export default [
  {
    title: 'Dashboard',
    route: 'home',
    icon: 'HomeIcon',
  },
  {
    header: 'Aplications',
  },
  {
    title: 'Clients',
    route: 'listclient',
    icon: 'UserIcon',
  },
  // {
  //   title: 'Notes de frais',
  //   route: 'listfrais',
  //   icon: 'ClipboardIcon',
  // },
  {
    header: 'Supervision',
  },
  {
    title: 'Rapport Serenity',
    route: 'serenityrapport',
    icon: 'MailIcon',
  },
  {
    title: 'Suivi Gestion',
    route: 'backupgestionrapport',
    icon: 'DownloadCloudIcon',
  },
  // {
  //   header: 'Tests',
  // },
  // {
  //   title: 'Camera & GPS',
  //   route: 'camera',
  //   icon: 'CameraIcon',
  // },
  // {
  //   title: 'Barcode Reader',
  //   route: 'barcodereader',
  //   icon: 'CameraIcon',
  // },
]
